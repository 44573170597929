import React, { useEffect } from "react";
import MenuDrawer from "../Components/MenuDrawer";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, OutlinedInput, Typography, TableBody } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderNew from "../Components/HeaderNew";
import { styled } from "@mui/material/styles";
import Paper, { paperClasses } from "@mui/material/Paper";
import { DetailCards } from "../Common-comp/Card";
import CustomizedAccordions from "../Common-comp/Accordian";
import GoalGraph from "../Common-comp/GoalGraph";
import { getGoalTrackerData } from "../services/Analysis_api";
import { typographyStyles } from "../styleSheets/StyleNew";
import MultipleSelect from "../Common-comp/SelectField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from "recharts";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar } from "recharts";
import { ResponsiveContainer } from "recharts";
import { Tooltip } from "recharts";
import ErrorPage from "./ErrorPage";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { GiStairsGoal } from "react-icons/gi";
import ExampleAlignmentButtons from "../Common-comp/TextView";
import { Card, CardContent } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";
import { SubHeading } from "./../styleSheets/Style";
import Latex from "react-latex-next";
import GoalTrackerMob from "./mobPages/GoalTrackerMob";
import { getEntityType } from '../services/Analysis_api';

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  textAlign: "",
  height: "80vh",
  bgcolor: "white",
  borderRadius: "10px ",
  boxShadow: 24,
  p: 2,
  backroundSize: "cover",
  backgroundRepeat: "no-repeat",
  objectfit: "cover",

  // Add this line to set a higher z-index value
};

export default function GoalTracker() {
  const navigate = useNavigate();
  const [mockList, setMockList] = useState();
  const [mockData, setMockData] = useState();
  const [mockIndex, setMockIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const [isLoading, setLoading] = useState(true);
  const [entityType, setEntityType] = useState();

  const handleClose = () => {
    setContent(null);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    getEntityTypeData()
  }, [])

  const getEntityTypeData = async () => {

    const res = await getEntityType(attemptId, uid);
    // console.log(res);
    if (res?.status == 200) {
      console.log(res?.data?.data?.entityType?.title);
      setEntityType(res?.data?.data?.entityType?.title)
    } else {
      console.log("error", res);

    }
  };

  // api call
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (mockList?.length) {
      setMockData(mockList[mockIndex]);
    }
  }, [mockIndex, mockList]);

  const cellStyle = {
    borderBottom: "none",
    fontSize: 13,
    paddingBottom: 2,
  };
  const ITEM_HEIGHT = "48";
  const ITEM_PADDING_TOP = 3;
  const MenuProps = {
    MenuListProps: {
      autoFocusItem: true,
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const infoStyle = {
    textstyle: {
      fontSize: "10px",
      fontFamily: "var(--font-inter)",
      fontWeight: "bold",
    },
    divStyle: {
      width: "46px",
      height: "11px",
      background: "linear-gradient(180deg, #21D3E7 0%, #0099F4 100%)",
      borderRadius: "20px",
    },
  };

  const { attemptId, mockId } = useParams();
  const [weakTopics, setWeakTopics] = useState();
  const [bschool, setBschool] = useState([]);
  const [missedBschool, setMissedBschool] = useState([]);
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { uid } = useSelector((state) => state.userData);
  const [view, setView] = useState("Percentile(Radar)");
  const [articleList, setArticleList] = useState();
  const [isEnlarged] = useState();
  const { isMobile } = useSelector((state) => state.globalData);

  console.log(articleList);

  // function for fetching data

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getGoalTrackerData(attemptId, uid);
      console.log(res);
      if (res?.status == 200) {
        setWeakTopics(res.data.data.weakTopics[0]);
        setBschool(res.data.data.bschools);
        setMissedBschool(res.data.data.missedBSchools);
        setMockList(res.data.data.mockWise);
        setArticleList(res.data.data.articleList);
        setLoading(false);
      } else {
        ////console.log("error", res);
        setIsErr(true);
        setErrorMsg("Error While Fetching Data! Please Reload.");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsErr(true);
      setErrorMsg("Error While Fetching Data! Please Reload.");
      setLoading(false);
    } finally {
      console.log("GOAL TRACKER FUNCTION CALLED");
    }
  };

  return (
    <div style={{ userSelect: "none" }}>
      <Box sx={{ width: "100vw", height: "100vh" }}>
        <Box>{!isMobile && <MenuDrawer />}</Box>

        <Box
          sx={{
            ml: !isMobile && "65px",
            background: isLoading ? "" : entityType === "JEE" ? "url(/iitDelhi.jpg)" : entityType === "NEET" ? "url(/medical.jpg)" : "url(/GoalTrackerBackground.jpg)",
            backgroundSize: "cover",
            height: "100vh",
            p: 2,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            overflow: "scroll",

          }}
        >
          {/* Header */}
          {isLoading ? (
            <></>
          ) : (
            <Box component="header">
              <HeaderNew logoPath={"/iQuantaWhite.png"} style={{ color: "white" }} />
            </Box>
          )}

          {/* Header end  */}
          {/* main Section start */}
          {isLoading ? (
            <div
              className="d-flex align-items-center flex-column gap-2 justify-content-center"
              style={{ width: "100%", height: "90vh" }}
            >
              <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-text">Loading...</div>
              </div>
            </div>
          ) : isMobile ? (
            <GoalTrackerMob />
          ) : (
            <>
              <Box component="div" sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                <Typography sx={{ ...typographyStyles.mainHeading }} color="white">
                  {" "}
                  Goal Tracker via iRadar <GiStairsGoal className="pb-2" />
                </Typography>{" "}
                {/* Two div */}
                <Box component="div">
                  <Box
                    component="div"
                    sx={{
                      position: "absolute",
                      marginTop: 15,
                      zIndex: 0,
                    }}
                  >
                    {view === "Percentile(Radar)" ? (
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          duration: 1.0,
                        }}
                      >
                        <ResponsiveContainer width={700} height={480}>
                          <RadarChart outerRadius={220} data={mockData?.data} legendType="plain">
                            <PolarGrid />
                            <PolarAngleAxis dataKey="name" tick={{ fill: "white" }} />
                            {/* <PolarRadiusAxis angle={30} domain={[0, 100]} /> */}
                            <Radar
                              name="Targeted %ile"
                              dataKey="targetPercentile"
                              stroke="#7673FF"
                              fill="#7673FF"
                              fillOpacity={0.6}
                            />
                            <Radar
                              name="Scored %ile"
                              dataKey="percentile"
                              stroke="#59DE66"
                              fill="#59DE66"
                              fillOpacity={0.6}
                            />
                            <Tooltip />
                            <Legend
                              layout="horizontal"
                              verticalAlign="bottom"
                              align="center"
                              wrapperStyle={{ marginBottom: 75 }}
                            />
                          </RadarChart>
                        </ResponsiveContainer>
                      </motion.div>
                    ) : view === "Score(Bar)" ? (
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          duration: 1.0,
                        }}
                      >
                        <BarChart width={600} height={380} data={mockData?.data}>
                          {/* <CartesianGrid strokeDasharray="3 3" /> */}
                          <XAxis dataKey="name" tick={{ fill: "white" }} />
                          <YAxis domain={[0, 100]} tick={{ fill: "white" }} />
                          <Tooltip />
                          <Legend />
                          <Bar barSize={60} dataKey="targetScore" fill="#641CFF" />
                          <Bar barSize={60} dataKey="yourScore" fill="#59DE66" />
                        </BarChart>
                      </motion.div>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <Box component="div" sx={{ display: "flex", gap: 2 }}>
                  <Box
                    component="div"
                    sx={{
                      width: "40vw",
                      height: "fit-content",
                      zIndex: 999,
                      borderRadius: "25px",
                      background: "white",
                      p: 1,
                      position: "absolute",
                      top: 450,
                      right: 50,
                    }}
                  >
                    <Box>
                      <Card
                        className={isEnlarged ? "enlarged" : ""}
                        sx={{
                          overflow: "scroll",
                          width: "100%",
                          height: "100%",
                          borderRadius: "25px",
                          boxShadow: "none",
                        }}
                      // onClick={() => setIsEnlarged(!isEnlarged)}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            width: "100%",
                            fontFamily: "var(--font-inter)",
                            flexDirection: "column",
                            gap: 4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                            className="d-flex"
                          >
                            <img src={"/CardsIcons/idea1.png"} className="img-fluid me-2" alt="" width={22} />
                            <Typography fontFamily={"Poppins"} variant="h4" color="black" fontSize={18}>
                              {"Personalized Expert Analysis & Strategy"}
                            </Typography>
                          </div>
                          <div>
                            {articleList?.map((item, ind) => (
                              <Accordion key={ind}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>{item.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {item.topics?.map((e, ind) => (
                                    <Button
                                      key={ind}
                                      onClick={() => {
                                        setContent(e.article);
                                        handleOpen();
                                      }}
                                    >
                                      {e.topic}
                                    </Button>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </div>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      width: "40vw",
                      height: 300,
                      borderRadius: "17px",
                      background: "white",
                      zIndex: 99,
                      p: 1,
                      overflowX: "hidden",
                      position: "absolute",
                      top: 105,
                      right: 50,
                    }}
                  >
                    <DetailCards
                      logoPath={"/goalSchool.png"}
                      cardContent={
                        <TableContainer>
                          <Table sx={{ border: "none" }} aria-label="simple table">
                            <TableBody>
                              {bschool &&
                                bschool.map((item, ind) => {
                                  return (
                                    <TableRow
                                      key={ind}
                                      sx={{
                                        lineHeight: "unset",
                                        borderBottom: "1px solid #E1E1E1",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          paddingRight: 2,
                                          fontWeight: 600,
                                        }}
                                        align="left"
                                      >
                                        {ind + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                          width: "40% !important",
                                        }}
                                        align="left"
                                      >
                                        {item.name}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                        }}
                                        align="left"
                                      >
                                        {item.highestSalary + " LPA" || "tbd"}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                        }}
                                        align="left"
                                      >
                                        {item.avgSalary + " LPA" || "tbd"}
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                          color: item.bschool === "Missed" ? "red" : "green",
                                        }}
                                        align="left"
                                      >
                                        {item.bschool}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }
                      heading={`${entityType === "JEE" ? "Top IITs You Can Crack" : entityType === "NEET" ? "Top Medical Colleges You Can Crack" : "B-Schools You Can Crack" || "B-Schools You Can Crack"}`}
                      style={{}}
                    />
                  </Box>
                </Box>
                {/* Graphs start */}
                <Box> </Box>
                <Box>
                  {" "}
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "15px ",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      p: 1,
                      columnGap: 2,
                      // backgroundColor: "white",
                      zIndex: 0,
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        height: 41,
                        borderRadius: "15px ",
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                        backgroundColor: "white",
                        zIndex: 0,
                      }}
                    >
                      {" "}
                      <FormControl
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 3,
                        }}
                      >
                        <Select
                          defaultValue={0}
                          // value={selected}

                          input={
                            <OutlinedInput
                              sx={{
                                width: "100%",
                                borderRadius: 2,
                                height: "100%",
                                fontSize: "12px",
                                fontWeight: 700,
                                fontFamily: "var(--font-inter)",

                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 1,
                                  borderColor: "transparent",
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                  border: 1,
                                  borderColor: "transparent",
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: 2,
                                  borderColor: "transparent",
                                },
                              }}
                            />
                          }
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Select value" }}
                        >
                          <MenuItem value={""} disabled>
                            <em>Select</em>
                          </MenuItem>
                          {mockList &&
                            mockList.map((item, ind) => (
                              <MenuItem
                                key={ind}
                                value={ind}
                                sx={{
                                  fontFamily: "var(--font-inter)",
                                  fontSize: "11px",
                                  fontWeight: "600",
                                }}
                                onClick={(e) => {
                                  setMockIndex(ind);
                                }}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography sx={{ letterSpacing: 1 }} fontSize={16} color="white">
                      Select Your Map
                    </Typography>
                    <div>
                      <ExampleAlignmentButtons
                        setValue={setView}
                        value={view}
                        options={[
                          {
                            label: "Percentile(Radar)",
                            image: "/tableView.png",
                          },
                          {
                            label: "Score(Bar)",
                            image: "/graphView.png",
                          },
                        ]}
                        style={{
                          backgroundMain: "#b7bdb9",
                          higlightBackground: "#b7bdb9",
                          buttonWidth: 145,
                          buttonHeight: 40,
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                <Box sx={{ zIndex: 1000 }}>
                  {" "}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{ ...style, overflowY: "scroll" }}>
                      <div className="d-flex justify-content-center">
                        <SubHeading className="m-0 ps-3">{""}</SubHeading>
                      </div>
                      <Typography>
                        <Latex>{content ? content : ""}</Latex>
                      </Typography>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </>
          )}
          {/* main Section end */}
        </Box>
      </Box>
    </div>
  );
}
