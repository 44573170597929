import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Card,
  Stack,
  Tooltip,
  Fab,
  Divider,
  Button,
} from "@mui/material";
import { ModifyButton } from "../styleSheets/Style";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { useLocation, useParams, Link } from "react-router-dom";
import HeaderNew from "../Components/HeaderNew";
import "../styleSheets/AnalysisMain.css";
import { PacmanLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuDrawer from "../Components/MenuDrawer";
import { typographyStyles } from "../styleSheets/StyleNew";
import { ApexChart } from "../Common-comp/CircleChart";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { getEntityType } from '../services/Analysis_api'
const DashBoardAnalysis = () => {
  const { name, email, _id, uid } = useSelector((state) => state.userData);
  const {
    basicAnalysis = {},
    isScorePercentile,
    isGoalTracker,
    overallAnalysis = [],
    isWindowPeriod,
    createdAt,
  } = useSelector((state) => state.analysisData);
  //const dateString = "Fri Dec 29 2023 10:32:05 GMT+0000";
  const date = new Date(createdAt);

  // Formatting options
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [tip, setTip] = useState(
    "Review Your Performance: Analyze your mock test results to understand your strengths and weaknesses."
  );
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const navigate = useNavigate();
  const [allSection] = overallAnalysis?.slice(1);
  const { mockId, attemptId } = useParams();
  const [entityType, setEntityType] = useState();
  const {
    accuracy,
    potentialScore,
    overallScore,
    sectionName,
    negativeMarks,
    overallPercentage,
    Backdrop,
    title,
    percentile,
    air,
    targetPercentile,
    sections,
    analyticsMetrics,
    tipsList = [],
    unattemptedThreshold,
    unattemptedMarksValue,
    incorrectQuestionDeduction,
    unattemptedThresholdBoolean = false,
  } = basicAnalysis;
  const { incorrect, correct, attempted, questions } = allSection || {};
  const CardsData = [
    {
      tooltip: `
      Guess what? If you have kept 100% accuracy you would have scored this much. 
      Work on your accuracy, You have good Potential.
      `,
      cardTitle: potentialScore,
      icon: "/PM.png",
      title: "Potential Marks",
    },
    {
      tooltip: `This is sad! Try to minimise your negative mark. You will see marginal improvement then.`,
      cardTitle: negativeMarks,
      icon: "/NM.png",
      title: "Negative Marks",
    },
    {
      tooltip: `This shows percentage of questions you have answered correctly out of your total attempt. Stay Focused!!`,
      cardTitle: accuracy == "NaN" ? "NA" : accuracy,
      icon: "/Acc.png",
      title: "Your Accuracy",
    },
    {
      tooltip:
        "Simple percentage formula it  is :p (your score/Total score)*100 ",
      cardTitle: +overallPercentage < 0 ? 0 : +overallPercentage,
      icon: "/PS.png",
      title: "Overall Percentage",
    },
  ];
  const pulseAnimation = {
    scale: [1, 1.1, 1],
    transition: {
      duration: 1.5,
      ease: "easeInOut",
      repeat: Infinity,
    },
  };



  useEffect(() => {
    getEntityTypeData()
  }, [])

  const getEntityTypeData = async () => {

    const res = await getEntityType(attemptId, uid);
    // console.log(res);
    if (res?.status == 200) {
      console.log(res?.data?.data?.entityType?.title);
      setEntityType(res?.data?.data?.entityType?.title)
    } else {
      console.log("error", res);

    }
  };

  return (
    <div
      style={{
        // padding: "1.5em 2em",
        gap: "1.5em",
      }}
    >
      {/* First main div */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexBasis: "30%",
          marginTop: 7,
        }}
      >
        <div>
          <Typography
            variant="h4"
            sx={{ color: "var(--dark-blue)", fontSize: "33px" }}
          >
            Hey {name},
            <br />
          </Typography>
          <Typography
            sx={{
              fontSize: "25px",
              color: "black",
              fontWeight: 600,
              marginTop: 0.9,
            }}
          >
            This is your mock Analysis for
            {title ? " " + title : " NoMockTitle"}.
          </Typography>
          <Typography m={1} fontSize="16px">
            {" "}
            Attempted On:{" "}
            <span style={{ color: "Blue" }}> {formattedDate}</span>
          </Typography>
          <div className="d-flex justify-content-start  gap-2 ">
            {isScorePercentile && (
              <button
                onClick={() =>
                  navigate(`/scorevsprecentile/${mockId}/${attemptId}`)
                }
                className="glow-on-hover"
                type="button"
              >
                Score vs Percentile
              </button>
            )}
          </div>
        </div>
        {/* <h6 style={{fontSize : "10px", textAlign : "center"}}>Made with ❤ by iQuanta Tech</h6> */}
      </div>
      {/* second main div */}
      <div
        style={{
          gap: "18px",
        }}
      >
        <div style={{ gap: "18px", marginTop: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Card
              className="scrollbar-hide"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 4,
                textAlign: "center",
                boxShadow: 4,

                overflow: { xs: "scroll", sm: "hidden", md: "hidden" },
              }}
            >
              <CardContent
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-around",
                  display: "flex",
                  alignItems: "center",
                  p: "1px !important",
                  pt: "10px!important",
                }}
              >
                <Typography
                  sx={{
                    ...typographyStyles.subHeading,
                    fontSize: "18px",
                    color: "var(--font-grey)",
                    mb: 2,
                    bgColor: "var(--light-grey)",
                  }}
                >
                  All India Rank
                </Typography>
                <img src="/india1.png" alt="india rank icon" width={"auto"} />

                <Typography
                  sx={{
                    ...typographyStyles.subHeading,
                    fontSize: String(air).length > 3 ? "25px" : "30px",

                    color: "#FF8B41",
                    marginRight: 2,
                  }}
                >
                  {air}
                </Typography>
              </CardContent>
            </Card>
            {/* ----------------- */}
            <Card
              className="scrollbar-hide"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 4,
                textAlign: "center",
                boxShadow: 4,
              }}
            >
              <CardContent
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-around",
                  display: "flex",
                  p: "1px !important",
                  pt: "10px!important",
                  alignItems: "center",
                }}
              >
                {/* Graph */}
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Typography
                    sx={{
                      ...typographyStyles.subHeading,
                      fontSize: "18px !important",
                      color: "var(--font-grey)",
                      // lineHeight: 0,
                    }}
                  >
                    Your Score
                  </Typography>
                  <ApexChart
                    show={{ name: true, value: true }}
                    series={[percentile ? percentile : overallScore]}
                    title={percentile ? "Percentile" : "Score"}
                    style={{
                      height: 150,
                      color: "#4149FF",
                      width: 150,
                      fontSize: 18,
                      titleSize: 12,
                      offsetValue: -2,
                      // offsetTitle: -2,
                    }}
                  />
                </div>
                <div
                  className="text-center"
                  style={{ position: "relative", bottom: "13px" }}
                >
                  <Typography
                    sx={{
                      ...typographyStyles.subHeading,
                      fontSize: "25px",
                    }}
                  >
                    {overallScore}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Box>


          <div
            style={{
              zIndex: 1000,
              display: "flex",
              flexBasis: "35%",
              gap: "18px",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: "100%",
                marginTop: 2,
                marginBottom: 2,
                height: "auto",
                borderRadius: 4,
                boxShadow: 4,
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    ...typographyStyles.subHeading,
                    fontSize: "19px",
                    color: "var(--font-grey)",
                  }}
                >
                  {sectionName?.toUpperCase()}
                </Typography>

                <Stack direction="column" fontSize={11} gap={1} marginTop={1}>
                  <div className="d-flex justify-content-between">
                    <Typography
                      sx={{
                        ...typographyStyles.subHeading,
                        fontSize: "14px",
                      }}
                    >
                      Result Percentile:
                    </Typography>
                    <Typography
                      sx={{
                        ...typographyStyles.subHeading,
                        fontSize: "14px",
                        color: "#4149FF",
                      }}
                    >
                      {percentile ? percentile : "N/A"}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Typography
                      sx={{
                        ...typographyStyles.subHeading,
                        fontSize: "14px",
                      }}
                    >
                      Your Accuracy:
                    </Typography>
                    <Typography
                      sx={{
                        ...typographyStyles.subHeading,
                        fontSize: "14px",
                        color: "#4149FF",
                      }}
                    >
                      {accuracy}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Typography
                      sx={{
                        ...typographyStyles.subHeading,
                        fontSize: "14px",
                      }}
                    >
                      Negative Marks:
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          ...typographyStyles.subHeading,
                          fontSize: "14px",
                          color: "#4149FF",
                        }}
                      >
                        {negativeMarks}
                      </Typography>
                      {unattemptedThresholdBoolean && (
                        <span>
                          <Tooltip
                            title={`Your incorrect questions deductions(${incorrect}*0.25 = ${incorrect * incorrectQuestionDeduction
                              }) + unattempted questions deductions (${questions - attempted
                              } - unattemptedThreshold(${unattemptedThreshold})*${unattemptedMarksValue}=${(
                                (questions - attempted - unattemptedThreshold) *
                                unattemptedMarksValue
                              ).toFixed(1)})   =   ${negativeMarks}`}
                            arrow
                          >
                            <Box sx={{ marginTop: "2px" }}>
                              {" "}
                              <img
                                src="/Group17.svg"
                                className="ms-2"
                                width={18}
                                alt="info icon"
                              />
                            </Box>
                          </Tooltip>
                        </span>
                      )}
                    </Box>
                  </div>
                </Stack>
              </CardContent>
            </Card>
          </div>


          {/* ---------------marks */}
          <div
            className="d-flex flex-column gap-3"
            style={{ flexBasis: "65%" }}
          >
            <div
              className="d-flex gap-3 flex-column"
              style={{ flexBasis: "100% !important" }}
            >
              {/* -----------------MARKS------------------------ */}
              <Card
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: 4,
                  boxShadow: 4,
                }}
              >
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      ...typographyStyles.subHeading,
                      fontSize: "18px",
                      color: "var(--font-grey)",
                      m: 1,
                      textAlign: "center",
                      fontWeight: "bold",
                      textTransform: "uppercase",

                      //   lineHeight: 3,
                    }}
                  >
                    Marks
                  </Typography>
                  <Divider />
                  <div className="d-flex justify-content-around align-items-center pt-2 flex-wrap row-gap-3 mt-2">
                    {CardsData &&
                      CardsData?.map((item, ind) => {
                        return (
                          <Tooltip title={item.tooltip} arrow>
                            <div
                              className="Details flex-item text-center gap-3 "
                              style={{ flexBasis: "50%" }}
                              key={ind}
                            >
                              <img
                                src={item.icon}
                                alt="icon"
                                width={40}
                                className={
                                  item.icon === "/PS.png"
                                    ? "image-fluid mb-1  align-self-center"
                                    : "image-fluid mb-1 align-self-center"
                                }
                              />
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "23px",
                                }}
                              >
                                {item.cardTitle}
                              </Typography>
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "13px",
                                }}
                              >
                                {item.title}
                              </Typography>
                            </div>
                          </Tooltip>
                        );
                      })}
                  </div>
                </CardContent>
              </Card>
            </div>
            {/* MINHEIGHT IS 57% */}
            <div>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 4,
                  boxShadow: 4,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",

                    gridTemplateColumns: "auto auto auto",
                    p: 1,
                  }}
                >
                  {sections?.length > 0 &&
                    sections?.map((item, ind) => {
                      return (
                        <div key={ind} className="">
                          <div className="d-flex flex-column justify-content-center  align-items-center pt-1">
                            <ApexChart
                              show={{ name: true, value: true }}
                              series={[
                                item.percentile
                                  ? item.percentile
                                  : item.netScore,
                              ]}
                              title={item.percentile ? "Percentile" : "Score"}
                              style={{
                                height: 130,
                                width: 85,
                                color: item.color,
                                fontSize: 15,
                                titleSize: 10,
                                offsetValue: -2,
                                offsetTitle: -2,
                              }}
                            />
                            <div className="info mb-2">
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "13.82px",
                                  height: "30px",
                                  color: "var(--font-grey)",
                                  fontWeight: 700,
                                  pt: 1,
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "16.96px",
                                }}
                              >
                                {item.netScore}
                              </Typography>
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "10px",
                                  color: "#5F5F5F",
                                }}
                              >
                                Your Score
                              </Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {sections?.length <= 4 ? (
                    <Box
                      sx={{
                        height: "auto",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gridColumn: sections?.length <= 3 ? "1/4" : "2/4",
                      }}
                    >
                      <Box width="100%" textAlign="center" gap={2}>
                        <Box>
                          <Typography
                            fontSize="18px"
                            fontWeight="600"
                            color="primary"
                          >
                            Post your score on iQuanta Community.
                          </Typography>
                          <Typography variant="body2">
                            iQuanta has India's biggest {entityType === "JEE" ? "IIT JEE" : entityType || "CAT"} community with
                            400,000 people in it.
                          </Typography>
                        </Box>
                        <Box sx={{ p: 1, mx: "auto" }}>
                          <motion.div animate={pulseAnimation} style={{}}>
                            <Fab
                              href={entityType === "JEE" ? "" : "https://www.facebook.com/groups/370097693157939"}
                              variant="extended"
                              color="primary"
                              sx={{
                                width: "200px",
                                height: "30px",
                                mt: 2,
                                textAlign: "center",
                                fontSize: "12px",
                                textTransform: "none",
                              }}
                            >
                              Post Now
                            </Fab>
                          </motion.div>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Card>
            </div>
          </div>
        </div>
        {/* -------------------------------||KNOW  YOUR AREAS CARD ||------------------------- */}
        <div style={{ flexBasis: "100%", marginTop: "20px" }}>
          <Card
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: 4,
              display: "flex",
              flexDirection: "column",
              boxShadow: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 0,
                gap: 2,
                p: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#0057CB",
                  fontSize: "15px",
                  fontWeight: 600,
                }}
              >
                Know your areas
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                {analyticsMetrics?.map((e, ind) => (
                  <Button
                    key={ind}
                    size="small"
                    sx={{
                      width: "auto",
                      height: ind === selectedSectionIndex ? "15px" : "0px",
                      bgcolor: ind === selectedSectionIndex ? "#0057CB" : "",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,

                      borderRadius: "8px",
                      "& :hover": {
                        bgColor: "#0057CB",
                        color: "black",
                      },
                    }}
                    onClick={() => setSelectedSectionIndex(ind)}
                  >
                    <Typography
                      key={ind}
                      sx={{
                        fontWeight: 600,
                        color: ind === selectedSectionIndex ? "white" : "black",
                        fontSize: "15px",
                      }}
                    >
                      {e.name}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                paddingLeft: 2,
                paddingRight: 2,
                marginTop: 1,
              }}
            >
              <Box
                sx={{
                  flexBasis: "100%",
                  height: "11em",
                  paddingBottom: 2,
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 4,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0057C5",
                      minHeight: "3em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {"Weak Spots"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ overflowY: "scroll", marginTop: 1 }}
                    className="scrollbar-hide"
                  >
                    {" "}
                    {analyticsMetrics?.[selectedSectionIndex]?.weakTopics?.map(
                      (item, index) => (
                        <Typography
                          key={index}
                          sx={{
                            paddingBottom: 1,
                            textAlign: "center",
                            color: "#464443",
                            fontWeight: 500,
                            fontSize: "11px",
                          }}
                        >
                          {item.subtopic}
                        </Typography>
                      )
                    )}
                  </Box>
                </Card>
              </Box>
              <Box
                sx={{
                  flexBasis: "100%",
                  height: "11em",
                  paddingBottom: 2,
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 4,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0057C5",
                      minHeight: "3em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                        color: "white",
                        px: "4px",
                      }}
                    >
                      {"Moderate Spots"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ overflowY: "scroll", marginTop: 2 }}
                    className="scrollbar-hide"
                  >
                    {analyticsMetrics?.[
                      selectedSectionIndex
                    ]?.moderateTopics?.map((item, index) => (
                      <Typography
                        key={index}
                        sx={{
                          paddingBottom: 1,
                          textAlign: "center",
                          color: "#464443",
                          fontWeight: 500,
                          fontSize: "11px",
                        }}
                      >
                        {item.subtopic}
                      </Typography>
                    ))}
                  </Box>
                </Card>
              </Box>{" "}
              <Box
                sx={{
                  flexBasis: "100%",
                  height: "11em",
                  paddingBottom: 2,
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 4,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0057C5",
                      minHeight: "3em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {"Strong Spots"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ overflowY: "scroll", marginTop: 2 }}
                    className="scrollbar-hide"
                  >
                    {analyticsMetrics?.[
                      selectedSectionIndex
                    ]?.strongTopics?.map((item, index) => (
                      <Typography
                        key={index}
                        sx={{
                          paddingBottom: 1,
                          textAlign: "center",
                          color: "#464443",
                          fontWeight: 500,
                          fontSize: "11px",
                        }}
                      >
                        {item.subtopic}
                      </Typography>
                    ))}
                  </Box>
                </Card>
              </Box>
            </Box>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DashBoardAnalysis;
