import React, { useEffect, useState } from "react";
import { Typography, Box, Card, Stack, Tooltip, useMediaQuery, useTheme, Fab } from "@mui/material";
import { ModifyButton } from "../styleSheets/Style";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { useLocation, useParams, Link } from "react-router-dom";
import HeaderNew from "../Components/HeaderNew";
import "../styleSheets/AnalysisMain.css";
import { PacmanLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuDrawer from "../Components/MenuDrawer";
import { typographyStyles } from "../styleSheets/StyleNew";
import { ApexChart } from "../Common-comp/CircleChart";
import { useDispatch, useSelector } from "react-redux";
import { addBasicAnalysisData, addMockTypeData } from "../store/slices/analysisDataSlice";
import { fetchAnalysisData } from "../services/Analysis_api";
import ErrorPage from "../Pages/ErrorPage";
import Button from "@mui/material/Button";
import DashBoardAnalysis from "./DashBoardAnalysis";
import { FaFingerprint } from "react-icons/fa6";
import { motion } from "framer-motion";
import { GiClick } from "react-icons/gi";
import { getEntityType } from '../services/Analysis_api';
function AnalysisMain() {
  const navigate = useNavigate();
  const { mockId, attemptId } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(true);
  const [menuBarOpen, setMenuBarOpen] = useState(false); //Globally state for menu bar
  const [entityType, setEntityType] = useState();
  const { name, email, _id, uid } = useSelector((state) => state.userData);
  const { isMobile } = useSelector((state) => state.globalData);
  const {
    basicAnalysis = {},
    isScorePercentile,
    isGoalTracker,
    overallAnalysis = [],
    isWindowPeriod,
    createdAt,
    currentAttemptCount
  } = useSelector((state) => state.analysisData);
  //const dateString = "Fri Dec 29 2023 10:32:05 GMT+0000";
  const date = new Date(createdAt);

  // Formatting options
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", options);

  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const {
    accuracy,
    potentialScore,
    overallScore,
    sectionName,
    negativeMarks,
    overallPercentage,
    Backdrop,
    title,
    percentile,
    air,
    targetPercentile,
    sections,
    analyticsMetrics,
    tipsList = [],
    unattemptedThreshold,
    unattemptedMarksValue,
    incorrectQuestionDeduction,
    unattemptedThresholdBoolean = false,
  } = basicAnalysis;
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [tip, setTip] = useState(
    "Review Your Performance: Analyze your mock test results to understand your strengths and weaknesses."
  );

  useEffect(() => {
    getEntityTypeData()
  }, [])

  const getEntityTypeData = async () => {

    const res = await getEntityType(attemptId, uid);
    // console.log(res);
    if (res?.status == 200) {
      console.log(res?.data?.data?.entityType?.title);
      setEntityType(res?.data?.data?.entityType?.title)
    } else {
      console.log("error", res);

    }
  };


  useEffect(() => {
    setInterval(() => {
      const randomIndex = Math.floor(Math.random() * tipsList?.length);
      setTip(tipsList?.[randomIndex]);
    }, 5000);
  }, []);

  //console.log(accuracy);

  // Restricting back routes

  useEffect(() => {
    window.history.pushState(null, document.title, location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, location.href);
    });
  }, []);

  const handlePopstate = () => {
    window.history.pushState(null, document.title, window.location.href);
  };
  const fetchBasicAnalysisData = async () => {
    try {
      const res = await fetchAnalysisData(attemptId, uid);
      console.log("🚀 ~ fetchBasicAnalysisData ~ res:", res);
      if (res.status === 200) {
        dispatch(addBasicAnalysisData(res.data?.data));
        dispatch(addMockTypeData(res.data?.mockData));
        setTimeout(() => setIsLoading(false), 1000);
      } else {
        console.log(res);
        setIsLoading(false);
        showToastMessage(res?.response.data?.error);
        dispatch(addBasicAnalysisData([]));
        dispatch(addMockTypeData([]));
        // alert("some error occur, pls try to reload");
      }
    } catch (err) {
      console.log(err);
      showToastMessage(err?.response?.data?.error);
      dispatch(addBasicAnalysisData([]));
      dispatch(addMockTypeData([]));
      // alert("some error occur, pls try to reload");
    }
  };

  useEffect(() => {
    //calling analysis data api
    fetchBasicAnalysisData();
  }, []);

  const showToastMessage = (obj) => {
    setIsErr(true);
    setErrorMsg(obj);
  };

  if (isErr) {
    return <ErrorPage error={errorMsg}></ErrorPage>;
  }

  const CardsData = [
    {
      tooltip: `
      Guess what? If you have kept 100% accuracy you would have scored this much. 
      Work on your accuracy, You have good Potential.
      `,
      cardTitle: potentialScore,
      icon: "/PM.png",
      title: "Potential Marks",
    },
    {
      tooltip: `This is sad! Try to minimise your negative mark. You will see marginal improvement then.`,
      cardTitle: negativeMarks,
      icon: "/NM.png",
      title: "Negative Marks",
    },
    {
      tooltip: `This shows percentage of questions you have answered correctly out of your total attempt. Stay Focused!!`,
      cardTitle: accuracy == "NaN" ? "NA" : accuracy,
      icon: "/Acc.png",
      title: "Your Accuracy",
    },
    {
      tooltip: "Simple percentage formula it  is :p (your score/Total score)*100 ",
      cardTitle: +overallPercentage < 0 ? 0 : +overallPercentage,
      icon: "/PS.png",
      title: "Overall Percentage",
    },
  ];
  const pulseAnimation = {
    scale: [1, 1.1, 1],
    transition: {
      duration: 1.5,
      ease: "easeInOut",
      repeat: Infinity,
    },
  };

  const [allSection] = overallAnalysis?.slice(1);
  console.log(allSection);
  const { incorrect, correct, attempted, questions } = allSection || {};

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PacmanLoader color="var(--orange)" size="80px" />
          <h5
            className="loader_title"
            style={{ textAlign: "center", marginTop: "1em" }}
          >
            Preparing Analysis!
          </h5>
        </div>
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            id="my-component"
            style={{
              background: "var(--background)",
              position: "absolute",
              left: isMobile ? "" : "65px",
              padding: "15px",
              width: isMobile ? "100%" : "calc(100% - 65px)",
            }}
          >
            {!isMobile && <MenuDrawer />}
            <div className=" ">
              {menuBarOpen && (
                <Backdrop
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                    color: "#fff",
                  }}
                  open={menuBarOpen}
                  onClick={() => setMenuBarOpen(false)}
                />
              )}
              {/* Header */}
              <div className="container-fluid p-0">
                <HeaderNew />
              </div>

              {/* Header end */}
              {/* main parent div */}

              {!isSmallScreen ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "1.5em 2em",
                    gap: "1.5em",
                  }}
                >
                  {/* First main div */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexBasis: "30%",
                    }}
                  >
                    <div>
                      <Typography
                        variant="h4"
                        sx={{ color: "var(--dark-blue)", fontSize: "37px" }}
                      >
                        Hey {name},
                        <br />
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "32px",
                          color: "black",
                          fontWeight: 600,
                          marginTop: 0.9,
                        }}
                      >
                        This is your mock Analysis for
                        {title ? " " + title : " NoMockTitle"}.
                      </Typography>
                      <Typography m={1} fontSize="16px">
                        {" "}
                        Attempted On:{" "}
                        <span style={{ color: "Blue" }}> {formattedDate}</span>
                      </Typography>
                      <Typography m={1} fontSize="16px">
                        {" "}
                        Attempt:{" "}
                        <span style={{ color: "Blue" }}> {currentAttemptCount || 1}</span>
                      </Typography>
                      <div className="d-flex justify-content-start  gap-2 ">
                        {isScorePercentile && (
                          <button
                            onClick={() =>
                              navigate(
                                `/scorevsprecentile/${mockId}/${attemptId}`
                              )
                            }
                            className="glow-on-hover"
                            type="button"
                          >
                            Score vs Percentile
                          </button>
                        )}
                      </div>
                    </div>
                    {/* <h6 style={{fontSize : "10px", textAlign : "center"}}>Made with ❤ by iQuanta Tech</h6> */}
                    <Tooltip
                      title={
                        isWindowPeriod && "Analysis will be available at 11pm"
                      }
                    >
                      <Card
                        sx={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          flexDirection: "row",
                          boxShadow: 4,
                          padding: 2,
                          borderRadius: "9px",
                          cursor: !isWindowPeriod && "pointer",
                          justifyContent: "space-between",
                        }}
                        onClick={
                          isWindowPeriod
                            ? undefined // No action if it's the window period
                            : () =>
                              navigate(
                                `/viewsolutions/${mockId}/${attemptId}`
                              )
                        }
                      >
                        <img
                          src="/questionAnswer1.svg"
                          alt="Q&A icon"
                          width={50}
                          height={50}
                          style={{ marginTop: "10px" }}
                        />
                        <Typography
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            padding: 1.5,
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          Analyse your mock with solution
                        </Typography>
                        <img
                          src="/Group378.svg"
                          alt="Q&A icon"
                          width={30}
                          height={30}
                          style={{ marginTop: "20px" }}
                        />
                      </Card>
                    </Tooltip>

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                      }}
                    >
                      <Box sx={{ position: "relative", marginTop: "-35px" }}>
                        <img
                          src="/down-filled-triangular-arrow.png"
                          alt="arrow icon"
                          width={55}
                          height={55}
                          style={{ marginTop: "20px" }}
                        />
                      </Box>

                      <Tooltip
                        title={
                          isWindowPeriod &&
                          "Error Tracker will be available at 11pm"
                        }
                      >
                        <Card
                          sx={{
                            width: "80%",
                            height: "100px",
                            display: "flex",
                            flexDirection: "row",
                            boxShadow: 4,
                            padding: 2,
                            borderRadius: "9px",
                            cursor: !isWindowPeriod && "pointer",
                            justifyContent: "space-between",
                          }}
                          onClick={
                            isWindowPeriod
                              ? undefined // No action if it's the window period
                              : () =>
                                navigate(
                                  `/errortracker/${mockId}/${attemptId}`
                                )
                          }
                        >
                          <img
                            src="/radar2.svg"
                            alt="Q&A icon"
                            width={50}
                            height={50}
                            style={{ marginTop: "10px" }}
                          />
                          <Typography
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              padding: 1.5,
                              fontWeight: 600,
                              fontSize: "15px",
                            }}
                          >
                            Then Track your Errors here{" "}
                          </Typography>
                          <img
                            src="/Group379.svg"
                            alt="Q&A icon"
                            width={30}
                            height={30}
                            style={{ marginTop: "20px" }}
                          />
                        </Card>
                      </Tooltip>
                    </Box>
                    <Card
                      sx={{
                        width: "100%",
                        height: "180px",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: 4,
                        padding: 2,
                        borderRadius: "9px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <img
                          src="/strategy1.svg"
                          alt="chess icon"
                          width={50}
                          height={50}
                          style={{ marginTop: "10px" }}
                        />
                        <Typography
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            padding: 1.5,
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          Check your cracked{" "}
                          <span
                            style={{
                              color: "#FFAA17",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                          >
                            {entityType === "JEE"
                              ? "Top IITs"
                              : entityType === "NEET"
                                ? "Top Medical Colleges"
                                : entityType === "CAT" ? "B-SCHOOLS" : entityType || "B-SCHOOLS"}
                          </span>{" "}
                          on the basis of your current mock result & Strategies
                          to Crack Them.{" "}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#FFAA17",
                            borderRadius: "9px",
                          }}
                          onClick={() =>
                            isGoalTracker &&
                            navigate(`/goaltracker/${mockId}/${attemptId}`)
                          }
                          disabled={!isGoalTracker}
                        >
                          Check Now
                        </Button>
                      </Box>
                    </Card>
                  </div>
                  {/* second main div */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexBasis: "70%",
                      gap: "18px",
                    }}
                  >
                    <div
                      style={{ display: "flex", flexBasis: "60%", gap: "18px" }}
                    >
                      <div
                        style={{
                          zIndex: 1000,
                          display: "flex",
                          flexBasis: "35%",
                          gap: "18px",
                          flexDirection: "column",
                        }}
                      >
                        <Card
                          sx={{
                            width: "100%",
                            height: 300,
                            borderRadius: 4,
                            boxShadow: 4,
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{
                                ...typographyStyles.subHeading,
                                fontSize: "19px",
                                color: "var(--font-grey)",
                              }}
                            >
                              {sectionName?.toUpperCase()}
                            </Typography>
                            {/* Graph */}
                            <div className="d-flex align-items-center justify-content-center">
                              <ApexChart
                                show={{ name: true, value: true }}
                                series={[
                                  percentile ? percentile : overallScore,
                                ]}
                                title={percentile ? "Percentile" : "Score"}
                                style={{
                                  height: 150,
                                  color: "#4149FF",
                                  width: 150,
                                  fontSize: 18,
                                  titleSize: 12,
                                  offsetValue: -2,
                                  // offsetTitle: -2,
                                }}
                              />
                            </div>
                            <div
                              className="text-center"
                              style={{ position: "relative", bottom: "13px" }}
                            >
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "19.22px",
                                }}
                              >
                                {overallScore}
                              </Typography>
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "16px !important",
                                  color: "var(--font-grey)",
                                  // lineHeight: 0,
                                }}
                              >
                                Your Score
                              </Typography>
                            </div>
                            <Stack
                              direction="column"
                              fontSize={11}
                              gap={1}
                              marginTop={2}
                            >
                              <div className="d-flex justify-content-between">
                                <Typography
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize: "14px",
                                  }}
                                >
                                  Result Percentile:
                                </Typography>
                                <Typography
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize: "14px",
                                    color: "#4149FF",
                                  }}
                                >
                                  {percentile ? percentile : "N/A"}
                                </Typography>
                              </div>
                              <div className="d-flex justify-content-between">
                                <Typography
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize: "14px",
                                  }}
                                >
                                  Your Accuracy:
                                </Typography>
                                <Typography
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize: "14px",
                                    color: "#4149FF",
                                  }}
                                >
                                  {accuracy}
                                </Typography>
                              </div>
                              <div className="d-flex justify-content-between">
                                <Typography
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize: "14px",
                                  }}
                                >
                                  Negative Marks:
                                </Typography>
                                <Box
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Typography
                                    sx={{
                                      ...typographyStyles.subHeading,
                                      fontSize: "14px",
                                      color: "#4149FF",
                                    }}
                                  >
                                    {negativeMarks}
                                  </Typography>
                                  {unattemptedThresholdBoolean && (
                                    <span>
                                      <Tooltip
                                        title={`Your incorrect questions deductions(${incorrect}*0.25 = ${incorrect * incorrectQuestionDeduction
                                          }) + unattempted questions deductions (${questions - attempted
                                          } - unattemptedThreshold(${unattemptedThreshold})*${unattemptedMarksValue}=${(
                                            (questions -
                                              attempted -
                                              unattemptedThreshold) *
                                            unattemptedMarksValue
                                          ).toFixed(1)})   =   ${negativeMarks}`}
                                        arrow
                                      >
                                        <Box sx={{ marginTop: "2px" }}>
                                          {" "}
                                          <img
                                            src="/Group17.svg"
                                            className="ms-2"
                                            width={18}
                                            alt="info icon"
                                          />
                                        </Box>
                                      </Tooltip>
                                    </span>
                                  )}
                                </Box>
                              </div>
                            </Stack>
                          </CardContent>
                        </Card>
                        <Card
                          sx={{
                            width: "100%",
                            height: "100px",
                            display: "flex",
                            flexDirection: "row",
                            boxShadow: 4,
                            padding: 2,
                            borderRadius: "9px",
                            cursor: "pointer",
                            justifyContent: "space-between",
                          }}
                          onClick={() =>
                            isGoalTracker &&
                            navigate(`/goaltracker/${mockId}/${attemptId}`)
                          }
                        >
                          <img
                            src="/goal1.svg"
                            alt="Q&A icon"
                            width={50}
                            height={50}
                            style={{ marginTop: "10px" }}
                          />
                          <Typography
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              padding: 1,
                              fontWeight: 600,
                              fontSize: "15px",
                            }}
                          >
                            Check how far you are from your Goal!{" "}
                          </Typography>
                          <img
                            src="/Group390.svg"
                            alt="Q&A icon"
                            width={30}
                            height={30}
                            style={{ marginTop: "20px" }}
                          />
                        </Card>
                      </div>
                      <div
                        className="d-flex flex-column gap-3"
                        style={{ flexBasis: "65%" }}
                      >
                        <div
                          className="d-flex gap-3"
                          style={{ flexBasis: "30% !important" }}
                        >
                          <Card
                            sx={{
                              width: "30%",
                              height: "10.125em",
                              borderRadius: 4,
                              textAlign: "center",
                              boxShadow: 4,
                            }}
                          >
                            <CardContent
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 5,
                              }}
                            >
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "14px",
                                  color: "var(--font-grey)",
                                }}
                              >
                                All India Rank
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 3,
                                  alignItems: "center"
                                }}
                              >
                                <img
                                  src="/india1.png"
                                  alt="india rank icon"
                                  width={60}
                                />

                                {/* <Typography
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize: "32px",
                                    color: "#FF8B41",
                                  }}
                                >
                                  {air}
                                </Typography> */}

                                <Box component="heading"
                                  sx={{
                                    ...typographyStyles.subHeading,
                                    fontSize:
                                      String(air).length > 3
                                        ? "20px"
                                        : "35px",

                                    color: "#FF8B41",
                                    marginRight: 2,
                                  }}
                                >
                                  {air}
                                </Box>
                              </div>
                            </CardContent>
                          </Card>

                          <Card
                            sx={{
                              width: "70%",
                              height: "10.125em",
                              borderRadius: 4,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-evenly",
                              boxShadow: 4,
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  ...typographyStyles.subHeading,
                                  fontSize: "20px",
                                  color: "var(--font-grey)",
                                  lineHeight: 3,
                                }}
                              >
                                Marks
                              </Typography>
                              <div className="d-flex justify-content-around align-items-center">
                                {CardsData &&
                                  CardsData?.map((item, ind) => {
                                    return (
                                      <Tooltip title={item.tooltip} arrow>
                                        <div
                                          className="Details flex-item text-center"
                                          key={ind}
                                        >
                                          <img
                                            src={item.icon}
                                            alt="icon"
                                            width={35}
                                            className={
                                              item.icon === "/PS.png"
                                                ? "image-fluid mb-2 pb-2 align-self-center"
                                                : "image-fluid mb-2 align-self-center"
                                            }
                                          />
                                          <Typography
                                            sx={{
                                              ...typographyStyles.subHeading,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {item.cardTitle}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...typographyStyles.subHeading,
                                              fontSize: "10px",
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                        </div>
                                      </Tooltip>
                                    );
                                  })}
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                        {/* MINHEIGHT IS 57% */}
                        <div>
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: 4,
                              boxShadow: 4,
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "auto auto auto",
                                p: 1,
                              }}
                            >
                              {sections?.length > 0 &&
                                sections?.map((item, ind) => {
                                  return (
                                    <div key={ind} className="">
                                      <div className="d-flex flex-row justify-content-center gap-2 align-items-center pt-1 ">
                                        <ApexChart
                                          show={{ name: true, value: true }}
                                          series={[
                                            item.percentile
                                              ? item.percentile
                                              : item.netScore,
                                          ]}
                                          title={
                                            item.percentile
                                              ? "Percentile"
                                              : "Score"
                                          }
                                          style={{
                                            height: 130,
                                            width: 85,
                                            color: item.color,
                                            fontSize: 15,
                                            titleSize: 10,
                                            offsetValue: -2,
                                            offsetTitle: -2,
                                          }}
                                        />
                                        <div className="info mb-2">
                                          <Typography
                                            sx={{
                                              ...typographyStyles.subHeading,
                                              fontSize: "13.82px",
                                              height: "30px",
                                              color: "var(--font-grey)",
                                              fontWeight: 700,
                                              pt: 1,
                                            }}
                                          >
                                            {item.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...typographyStyles.subHeading,
                                              fontSize: "16.96px",
                                            }}
                                          >
                                            {item.netScore}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...typographyStyles.subHeading,
                                              fontSize: "10px",
                                              color: "#5F5F5F",
                                            }}
                                          >
                                            Your Score
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              {sections?.length <= 4 ? (
                                <Box
                                  sx={{
                                    height: "7em",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gridColumn:
                                      sections?.length <= 3 ? "1/4" : "2/4",
                                  }}
                                >
                                  {/* <Box>
                                    <img src="/Academy11.svg" className="ms-2" width={85} alt="tips icon" />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      background: "black",
                                      clipPath: "polygon(15% 0, 100% 0, 100% 100%, 0% 100%)",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "70%",
                                        height: "90%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: "white",
                                          lineHeight: 1,
                                          fontWeight: 500,
                                          alignItems: "center",
                                          fontFamily: "fantasy",
                                        }}
                                      >
                                        {tip}
                                      </Typography>
                                    </Box>
                                  </Box> */}
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={4}
                                    justifyContent="space-between"
                                  >
                                    <Box pl={2}>
                                      <Typography
                                        fontSize="22px"
                                        fontWeight="600"
                                        color="primary"
                                      >
                                        Post your score on iQuanta Community.
                                      </Typography>
                                      <Typography variant="body2">
                                        iQuanta has India's biggest {entityType === "JEE" ? "IIT JEE " : entityType === "NEET" ? "NEET " : entityType === "CAT" ? "CAT " : entityType || "CAT "}
                                        community with 400,000 people in it.
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <motion.div
                                        animate={pulseAnimation}
                                        style={{}}
                                      >
                                        <Fab
                                          href={entityType === "JEE" ? "" : entityType === "NEET" ? "" : "https://www.facebook.com/groups/370097693157939"}
                                          variant="extended"
                                          color="primary"
                                          sx={{
                                            width: "150px",
                                            textAlign: "center",
                                            fontSize: "12px",
                                            textTransform: "none",
                                          }}
                                        >
                                          Post Now
                                        </Fab>
                                      </motion.div>
                                    </Box>
                                  </Box>
                                </Box>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Card>
                        </div>
                      </div>
                    </div>
                    <div style={{ flexBasis: "40%" }}>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 4,
                          display: "flex",
                          flexDirection: "column",
                          boxShadow: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 1,
                            gap: 8,
                            p: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#0057CB",
                              fontSize: "18px",
                              fontWeight: 600,
                            }}
                          >
                            Know your areas
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 8,
                            }}
                          >
                            {analyticsMetrics?.map((e, ind) => (
                              <Box
                                key={ind}
                                sx={{
                                  cursor: "pointer",
                                  width: "auto",
                                  height:
                                    ind === selectedSectionIndex
                                      ? "15px"
                                      : "0px",
                                  bgcolor:
                                    ind === selectedSectionIndex
                                      ? "#0057CB"
                                      : "",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  p: 2,
                                  borderRadius: "8px",
                                }}
                                onClick={() => setSelectedSectionIndex(ind)}
                              >
                                <Typography
                                  key={ind}
                                  sx={{
                                    fontWeight: 600,
                                    color:
                                      ind === selectedSectionIndex
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {e.name}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 6,
                            paddingLeft: 4,
                            paddingRight: 4,
                            marginTop: 1,
                          }}
                        >
                          <Box
                            sx={{
                              flexBasis: "30%",
                              height: "11em",
                              paddingBottom: 2,
                            }}
                          >
                            <Card
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#0057C5",
                                  minHeight: "3em",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {"Weak Spots"}
                                </Typography>
                              </Box>
                              {/* <hr
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "0.5em",
                            }}
                          /> */}
                              <Box sx={{ overflowY: "scroll", marginTop: 2 }}>
                                {" "}
                                {analyticsMetrics?.[
                                  selectedSectionIndex
                                ]?.weakTopics?.map((item, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      paddingBottom: 1,
                                      textAlign: "center",
                                      color: "#464443",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.subtopic}
                                  </Typography>
                                ))}
                              </Box>
                            </Card>
                          </Box>
                          <Box
                            sx={{
                              flexBasis: "30%",
                              height: "11em",
                              paddingBottom: 2,
                            }}
                          >
                            <Card
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#0057C5",
                                  minHeight: "3em",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {"Moderate Spots"}
                                </Typography>
                              </Box>
                              <Box sx={{ overflowY: "scroll", marginTop: 2 }}>
                                {analyticsMetrics?.[
                                  selectedSectionIndex
                                ]?.moderateTopics?.map((item, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      paddingBottom: 1,
                                      textAlign: "center",
                                      color: "#464443",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.subtopic}
                                  </Typography>
                                ))}
                              </Box>
                            </Card>
                          </Box>{" "}
                          <Box
                            sx={{
                              flexBasis: "30%",
                              height: "11em",
                              paddingBottom: 2,
                            }}
                          >
                            <Card
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#0057C5",
                                  minHeight: "3em",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {"Strong Spots"}
                                </Typography>
                              </Box>
                              <Box sx={{ overflowY: "scroll", marginTop: 2 }}>
                                {analyticsMetrics?.[
                                  selectedSectionIndex
                                ]?.strongTopics?.map((item, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      paddingBottom: 1,
                                      textAlign: "center",
                                      color: "#464443",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.subtopic}
                                  </Typography>
                                ))}
                              </Box>
                            </Card>
                          </Box>
                        </Box>
                      </Card>
                    </div>
                  </div>
                </div>
              ) : (
                <DashBoardAnalysis />
              )}
              {/* Detailing section End */}

              {/* Buttons for changing sections */}
              <div className=" d-flex mt-0 py-4">
                <div style={{ maxWidth: "100%", overflow: "scroll" }} className=" d-flex gap-3 ps-2 scrollbar-hide">
                  <NavLink to="overall" activeclassname="active" className="link flex-item">
                    <ModifyButton
                      variant="filled"
                      className="nav-button"
                      sx={{
                        p: 2,
                        height: "40px",
                        minWidth: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      Score Card
                    </ModifyButton>
                  </NavLink>
                  <NavLink
                    to="sectionwise"
                    activeclassname="active "
                    className="link flex-item"
                  >
                    <ModifyButton
                      variant="filled"
                      className="nav-button"
                      sx={{
                        p: 2,
                        height: "40px",
                        minWidth: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      Question-wise
                    </ModifyButton>
                  </NavLink>
                  <NavLink
                    to="topicwise"
                    activeclassname="active "
                    className="link flex-item"
                  >
                    <ModifyButton
                      variant="filled"
                      className="nav-button"
                      sx={{
                        p: 2,
                        height: "40px",
                        minWidth: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      Topic-wise
                    </ModifyButton>
                  </NavLink>

                  <NavLink
                    to="subtopicwise"
                    activeclassname="active "
                    className="link flex-item"
                  >
                    <ModifyButton
                      variant="filled"
                      className="nav-button"
                      sx={{
                        p: 2,
                        height: "40px",
                        minWidth: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      Subtopic-wise
                    </ModifyButton>
                  </NavLink>
                  <NavLink
                    to="difficulty"
                    activeclassname="active"
                    className="link flex-item"
                  >
                    <ModifyButton
                      variant="filled"
                      className="nav-button"
                      sx={{
                        p: 2,
                        height: "40px",
                        minWidth: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      Difficulty-wise
                    </ModifyButton>
                  </NavLink>
                </div>
                {isSmallScreen ? (
                  ""
                ) : (
                  <div
                    style={{ flexBasis: "25%" }}
                    className={
                      location.pathname ===
                        `/analysis/${mockId}/${attemptId}/overall`
                        ? "flex-item "
                        : "d-none"
                    }
                  >
                    <Box
                      component="span"
                      sx={{
                        boxShadow: "none",
                        textTransform: "none",
                        fontSize: "13px",
                        fontWeight: 500,
                        width: "auto",
                        height: "auto",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "20px",
                        lineHeight: 1.5,
                        backgroundColor: "var( --blue-new)",
                        fontFamily: "var(--font-inter)",
                      }}
                    >
                      Time spent on questions:
                    </Box>
                    <span>
                      <Tooltip
                        title={
                          "This will show total time spent by you on correct questions, incorrect questions and skipped questions"
                        }
                        arrow
                      >
                        <img
                          src="/Group17.svg"
                          className="ms-2"
                          width={20}
                          alt=""
                        />
                      </Tooltip>
                    </span>
                  </div>
                )}
              </div>
              <Outlet />
            </div>
          </motion.div>
        </>
      )}
    </>
  );
}

export default AnalysisMain;
